import { Archive, Book, Calendar, Moon, Sun } from "lucide-react"
import type { NextPage } from "next"
import { useTheme } from "next-themes"
import Head from "next/head"
import NextLink from "next/link"
import { forwardRef } from "react"
import InlineSVG from "react-inlinesvg"

import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Sheet,
  SheetContent,
  SheetTrigger,
  cn,
} from "@fourel/ui"

import Logo from "~/images/logo.svg"
import wingFallback from "~/images/wing.png"
import wing from "~/images/wing.webp"

const Bar = ({ className }: { className?: string }) => {
  return (
    <span
      className={cn(
        "border-round drop-shadow-3xl absolute start-[0.125rem] mx-auto block h-0.5 w-6 bg-[currentColor] dark:text-gray-400 dark:drop-shadow-none",
        className,
      )}
    />
  )
}

const BurgerIcon = () => {
  return (
    <span aria-hidden className="pointer-events-none relative block h-6 w-6">
      <Bar className="top-[0.2rem]" />
      <Bar className="top-[0.6875rem]" />
      <Bar className="bottom-[0.2rem]" />
    </span>
  )
}
export const ToggleMenuButton = forwardRef<HTMLButtonElement, { className?: string }>(
  ({ ...props }, ref) => {
    return (
      <Button
        variant={null}
        size="icon"
        ref={ref}
        {...props}
        aria-label="open nav bar"
        className={cn("relative cursor-pointer", props.className)}
      >
        <BurgerIcon />
      </Button>
    )
  },
)
ToggleMenuButton.displayName = "ToggleMenuButton"

export function ModeToggle({ className }: { className?: string }) {
  const { setTheme } = useTheme()

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant={null} size="icon" className={className}>
          <Sun className="rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
          <Moon className="absolute rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
          <span className="sr-only">Toggle theme</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem onClick={() => setTheme("light")}>Light</DropdownMenuItem>
        <DropdownMenuItem onClick={() => setTheme("dark")}>Dark</DropdownMenuItem>
        <DropdownMenuItem onClick={() => setTheme("system")}>System</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

const Home: NextPage = () => {
  return (
    <div>
      <Head>
        <title>Fourel Aviation</title>
        <meta name="description" content="Fourel Aviation website" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="relative grid grid-rows-[100vh,1fr] ">
        <div className="fourel_main_background_picture lg:px-20] relative w-full bg-cover px-6 md:px-10">
          <picture>
            <source srcSet={wing.src} type="image/webp" />
            <img
              src={wingFallback.src}
              alt="Wing"
              className="pointer-events-none absolute right-0 top-[55vh] z-0 max-w-[35vw]"
            />
          </picture>
          <header className="drop-shadow-3xl grid grid-cols-[1fr,1fr] gap-10 py-10 text-white md:grid-cols-[1fr,4fr] lg:grid-cols-[2fr,5fr] dark:text-gray-400 dark:drop-shadow-none">
            <div className="font-brand flex w-full items-center text-2xl/[2.25rem]">
              <NextLink href="/">
                <div className="mr-5 h-9 w-9 cursor-auto">
                  <InlineSVG src={Logo.src} />
                </div>
              </NextLink>
              <NextLink href="/">FOUREL</NextLink>
            </div>
            <nav className="w-full flex-1">
              <ul className="hidden justify-between font-bold uppercase md:flex">
                <li className="list-none hover:underline">
                  <NextLink href="/">Home</NextLink>
                </li>

                <li className="list-none hover:underline">
                  <NextLink href="/solutions">Solutions</NextLink>
                </li>

                <li className="list-none hover:underline">
                  <NextLink href="/team"> Team </NextLink>
                </li>
                <li className="list-none hover:underline">
                  <NextLink href="/for-investors">For investors</NextLink>
                </li>
                <ModeToggle className="h-6 w-6" />
              </ul>
              <div className="flex flex-row-reverse py-10 md:hidden">
                <Sheet>
                  <SheetTrigger asChild>
                    <ToggleMenuButton className="h-8 w-8" />
                  </SheetTrigger>
                  <SheetContent side="left" className="pt-10 dark:bg-gray-700">
                    <ul className="flex flex-col gap-8  text-base font-medium uppercase text-violet-950 dark:text-white">
                      <div className="font-brand flex w-full items-center text-2xl/[2.25rem]">
                        <NextLink href="/">
                          <div className="mr-5 h-9 w-9 cursor-auto">
                            <InlineSVG src={Logo.src} />
                          </div>
                        </NextLink>
                        <NextLink href="/">FOUREL</NextLink>
                        <ModeToggle className="ml-12 text-[20px]" />
                      </div>
                      <li className="list-none hover:underline">
                        <NextLink href="/">Home</NextLink>
                      </li>
                      <li className="list-none hover:underline">
                        <NextLink href="/solutions">Solutions</NextLink>
                      </li>
                      <li className="list-none hover:underline">
                        <NextLink href="/team">Team</NextLink>
                      </li>
                      <li className="list-none hover:underline">
                        <NextLink href="/for-investors">For investors</NextLink>
                      </li>
                    </ul>
                  </SheetContent>
                </Sheet>
              </div>
            </nav>
          </header>
          <section className="mt-[100px]">
            <p className="font-brand w-full max-w-[720px] text-2xl/[2.25rem] font-medium uppercase text-violet-950 dark:text-white">
              Operations Management & Marketing Solutions
            </p>
            <div className="mt-[100px] flex w-full max-w-[720px] flex-col justify-between gap-6 font-bold text-violet-950 min-[480px]:flex-row min-[480px]:gap-4 dark:text-white">
              <NextLink href="/solutions">
                <p className="hover:underline">
                  Solutions
                  <span
                    className={`inline-block pl-3 text-2xl/[2.25rem] font-extrabold text-gray-500 after:content-['_⌝'] dark:text-gray-300`}
                  />
                </p>
              </NextLink>
              <NextLink href="/team">
                <p className="hover:underline">
                  Team
                  <span
                    className={`inline-block pl-3 text-2xl/[2.25rem] font-extrabold text-gray-500 after:content-['_⌝'] dark:text-gray-300`}
                  />
                </p>
              </NextLink>
              <NextLink href="/for-investors">
                <p className="hover:underline">
                  For investors
                  <span
                    className={`inline-block pl-3 text-2xl/[2.25rem] font-extrabold text-gray-500 after:content-['_⌝'] dark:text-gray-300`}
                  />
                </p>
              </NextLink>
            </div>
          </section>
        </div>
        <main className="bg-gray-50 px-6 pb-40 pt-20 md:px-10 lg:px-20 dark:bg-gray-800">
          <h1 className="text-3xl/[2.80rem] font-medium dark:text-white">Solutions</h1>
          <div className="mt-12 flex flex-col justify-between gap-4 lg:flex-row">
            <div className="min-w-full max-w-[450px] border-t-2 border-gray-200 pt-12 lg:min-w-[180px] dark:border-gray-500">
              <h2 className="flex items-center text-3xl/[2.80rem] font-medium text-violet-950 dark:text-white">
                <Book className="mr-3 h-[30px] w-[30px] text-gray-500" /> CAMO
              </h2>
              <p className="mt-12 text-sm/[1.32rem] text-gray-500">
                Fourel CAMO – A number of custom integrations with major MRO solutions
                allow to digitize and automatically populate the LogBook data and reports,
                therefore significantly decreasing the volume of paperwork and probability
                of data loss or human-factor failures.
              </p>
            </div>
            <div className="min-w-full max-w-[450px] border-t-2 border-gray-200 pt-12 lg:min-w-[180px] dark:border-gray-500">
              <h2 className="flex items-center text-3xl/[2.80rem] font-medium text-violet-950 dark:text-white">
                <Calendar className="mr-3 h-[30px] w-[30px] text-gray-500" /> Flights
              </h2>
              <p className="mt-12 text-sm/[1.32rem] text-gray-500">
                Fourel Flights – Advanced operations flows built on top of integrations
                with the major flight planning and scheduling software allow to align the
                aircraft data with the flight management software, thus allowing to better
                track expenditures and calculate PNL for each booking, populate the
                reports and provide forecasts for new orders.
              </p>
            </div>
            <div className="min-w-full max-w-[450px] border-t-2 border-gray-200 pt-12 lg:min-w-[180px] dark:border-gray-500">
              <h2 className="flex items-center text-3xl/[2.80rem] font-medium text-violet-950 dark:text-white">
                <Archive className="mr-3 h-[30px] w-[30px] text-gray-500" /> Marketing
              </h2>
              <p className="mt-12 text-sm/[1.32rem] text-gray-500">
                Fourel Marketing – a number of top-notch all-in-one solutions designed for
                acquiring and managing high profile clients, allowing to streamline and
                automate the invoicing, accept and track payments, simplify and organize
                communications with the clients, flight managers, brokers etc.
              </p>
            </div>
          </div>
        </main>
        <footer className="bg-gray-900 px-6 py-10 md:px-10 lg:px-20">
          <div className="flex max-w-full justify-between md:max-w-[350px]">
            <NextLink href="tel:+13475379038">
              <p className="text-white">+1 347 537 9038</p>
            </NextLink>
            <NextLink href="mailto:contact@getfourel.com">
              <p className="text-white">contact@getfourel.com</p>
            </NextLink>
          </div>

          <div className="mt-10 flex max-w-full justify-between md:max-w-[350px]">
            <NextLink
              href="https://www.instagram.com/4brothers2unicorn"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="text-white">Instagram</p>
            </NextLink>
          </div>

          <p className="mt-10 text-gray-600">
            All rights reserved Fourel Aviation Solutions LTD.
          </p>
        </footer>
      </div>
    </div>
  )
}

export default Home
